import currencyShared from "barcelo-shared/currency";

bcl.c.hotelRoomList = {
  props: {
    cssSelector: "hotel-room-list",
    itemCssSelector: "c-hotel-room-list",
    headSelector: "c-head-hotel-info__booking-info-price",
    elementSelector: "element-room-JS",
    priceSelector: ".c-price__value-JS",
    hotelRoomSelector: ".c-hotel-room-list-JS",
    priceSelectorMybCurrencyTypeWrapper: ".c-price__curre-type-wrapper-JS",
    footerSelector: ".c-hotel-room-list__footer",
    footerPriceSelector: ".c-hotel-room-list__footer-price",
    footerUnavailableSelector: ".c-hotel-room-list__footer-unavailable",
    bookingButtonSelector: ".booking-button-JS",
    paginationClassName: "pagination-JS",
    hidePagClassName: "hidden",
    classAvailableFrom: "mod--available-from",
    closingHotelSelector: {
      node: "message-closing-hotel-JS",
      dayClosing: "day-date-closing-JS",
      monthClosing: "month-date-closing-JS",
      dayOpening: "day-date-opening-JS",
      monthOpening: "month-date-opening-JS",
      hotelName: "hotel-name-JS",
    },
    mybIconSelector: ".myb-icon-JS",
    mybFooterSelector: ".c-mybprice-JS",
    mybPriceSelector: ".c-mybprice__value-JS",
    mybPriceClass: "c-mybprice__value-JS",
    strikePriceSelector: ".c-price-normal-myb-JS",
    cleanRooms: false,
    addClassName: "addClass",
    removeClassName: "removeClass",
    dataPrices: [],
    numberPagination: 4,
    count: 0,
    price: 10000000000,
    priceMyb: 10000000000,
    priceDom: 0,
    priceMybDom: 0,
    indexToPromise: 0,
    rooms: {},
    dateOpen: null,
    firstLoad: true,
    firstPageLoad: true,
    hotelFinallyClosed: true,
    hotelId: null,
    mybPrices: false,
    msgBoxWrongConfig: "wrong-configuration-rooms-JS",
    fastBookingMsgBox: "limitPeopleMsgBox-JS",
    fastBookingMsgBoxOpened: false,
    closeMsgBox: "closeMsgBoxWrongConfig-JS",
    receivedEvents: 0,
    emittedEvents: 0,
    hasFetched: false,
    useOldAPI: false,
    restrictions: {
      sabre: {
        maxAdultOccupancyExceeded: "MaxAdultOccupancyExceeded",
        maxChildOccupancyExceeded: "MaxChildOccupancyExceeded",
        noAvailableInventory: "NoAvailableInventory",
      },
      bconnect: {
        maxAdultOccupancyExceeded: "MAX_ADULT_OCCUPANCY_EXCEEDED",
        maxChildOccupancyExceeded: "MAX_CHILD_OCCUPANCY_EXCEEDED",
        open: "OPEN",
        closed: "CLOSED",
        minStay: "MIN_STAY",
        maxStay: "MAX_STAY",
        leadDays: "LEAD_DAYS_TIME",
        noArrival: "NO_ARRIVAL",
        noDeparture: "NO_DEPARTURE",
      },
    },
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.hotelRoomList.init, true);
  },

  restartMessagesBox: function () {
    const wrongConfigMsgBox = document.querySelectorAll("." + bcl.c.hotelRoomList.props.msgBoxWrongConfig);
    if (wrongConfigMsgBox) {
      bcl.u.forEach(wrongConfigMsgBox, function (elem) {
        bcl.u.addClass(elem, "hidden");
      });
    }

    const fastBookingMsgBox = document.querySelectorAll("." + bcl.c.hotelRoomList.props.fastBookingMsgBox);
    if (fastBookingMsgBox) {
      bcl.u.forEach(fastBookingMsgBox, function (elem) {
        bcl.u.removeClass(elem, "hidden");
      });
    }
  },

  handleLoadDataInit: function ($rooms, roomRecommendedContainer) {
    event.preventDefault();

    const promise = new Promise((resolve) => {
      bcl.c.hotelRoomList.updateOrder(bcl.c.hotelRoomList.props.dataPrices);
      resolve();
    });

    promise
      .then(() => {
        setTimeout(function () {
          bcl.u.removeBhgLoading(document.querySelector("." + bcl.c.hotelRoomList.props.cssSelector));
          bcl.c.hotelRoomList.orderNotAvailableRoomsByPriorities();
          bcl.c.hotelRoomList.showWrongConfigBoxes();
          bcl.c.hotelRoomList.unBlurMybPricesIfNeeded();

          if (bcl.c.fastbooking.calendar.roundedCalendarisEnabled() && bcl.c.hotelRoomList.props.firstPageLoad && bcl.c.fastbooking.commonProps.calendar.obj.getNights() == 0) {
            bcl.c.hotelRoomList.props.firstPageLoad = false;
            bcl.c.fastbooking.showCalendar();
          }

          // Dispatch event for rooms reorder with target if needed
          document.dispatchEvent(new CustomEvent("loadedAllData-SortRooms"));

          bcl.c.hotelRoomList.recommendRoom($rooms[0]);
        }, 0);
      })
      .catch(function (reason) {
        roomRecommendedContainer?.style.height ? (roomRecommendedContainer.style.height = "inherit") : "";
        console.error("ERROR PROMISE UPDATE ORDER [" + reason + "]");
      });
  },

  init: function () {
    currencyShared.init();
    bcl.c.hotelRoomList.restartMessagesBox();

    const closeMsgBoxButtons = document.querySelectorAll("." + bcl.c.hotelRoomList.props.closeMsgBox);
    if (closeMsgBoxButtons) {
      bcl.u.forEach(closeMsgBoxButtons, function (elem) {
        elem.addEventListener("click", function () {
          bcl.c.hotelRoomList.restartMessagesBox();
        });
      });
    }

    bcl.c.hotelRoomList.checkOldAPI();

    bcl.u.utag.removeListener("hotel-room-list");

    const $container = document.getElementsByClassName(bcl.c.hotelRoomList.props.cssSelector);

    if (!$container || !$container[0]) {
      return;
    }

    if (!bcl.u.utag.props.enabledListener.includes("hotel-room")) {
      bcl.u.utag.props.enabledListener.push("hotel-room");
      bcl.u.utag.enableListener("general hotel-room");
    }

    bcl.c.hotelRoomList.props.$container = $container[0];

    bcl.c.hotelRoomList.props.$hotel = document.querySelector(".hotel-button-JS");
    if (bcl.c.hotelRoomList.props.$hotel) {
      bcl.c.hotelRoomList.props.hotelId = bcl.c.hotelRoomList.props.$hotel.dataset.hotel;
    }

    bcl.c.hotelRoomList.props.$messages = bcl.c.hotelRoomList.props.$container.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.node);

    bcl.c.hotelRoomList.props.mybPrices = bcl.c.hotelRoomList.props.$messages.dataset.mybprices === "true";
    bcl.c.hotelRoomList.props.mybPricesContainer = document.querySelector(bcl.c.hotelRoomList.props.mybPriceSelector);

    bcl.c.hotelRoomList.initNodePagination();

    if (bcl.c.fastbooking.commonProps.calendar.data && bcl.c.fastbooking.commonProps.calendar.data.startDate && bcl.c.fastbooking.commonProps.calendar.data.endDate) {
      const endDate = new Date(bcl.c.fastbooking.commonProps.calendar.data.endDate);

      bcl.c.hotelRoomList.props.filterDateFB = true;
      bcl.c.hotelRoomList.initSelectors(null, endDate);
    } else {
      bcl.c.hotelRoomList.initSelectors();
    }

    bcl.c.hotelRoomList.initHead();
    bcl.c.hotelRoomList.props.firstLoad = false;

    bcl.c.hotelRoomList.initDateClosing();
    bcl.c.hotelRoomList.initNodesClosing();

    const $rooms = bcl.c.hotelRoomList.props.$container.getElementsByClassName(bcl.c.hotelRoomList.props.itemCssSelector);
    bcl.c.hotelRoomList.cleanArticlesRoomsContainersToSort($rooms);

    bcl.c.hotelRoomList.props.numHotels = $rooms.length;

    bcl.u.addBhgLoading(document.querySelector("." + bcl.c.hotelRoomList.props.cssSelector));

    bcl.c.hotelRoomList.emittedEvents = 0;
    bcl.c.hotelRoomList.receivedEvents = 0;

    if (bcl.c.hotelRoomList.props.useOldAPI) {
      Array.prototype.slice.call($rooms).forEach(function (value, index) {
        bcl.c.hotelRoomList.initRoom(value, index);
        bcl.c.hotelRoomList.emittedEvents++;
      });
    } else {
      const priceParams = new URLSearchParams({
        start_date: bcl.c.hotelRoomList.props.selectors.startDate,
        end_date: bcl.c.hotelRoomList.props.selectors.endDate,
        market: document.querySelector("body").getAttribute("data-ratefilter"),
        adults: bcl.c.hotelRoomList.props.selectors.numberAdults,
        children: bcl.c.hotelRoomList.props.selectors.numberChildren,
      });

      if (!bcl.c.hotelRoomList.props.hasFetched && bcl.c.hotelRoomList.props.hotelId) {
        let pricesUrl = document.body.dataset.bconnectUrl.replace("[HOTEL_ID]", bcl.c.hotelRoomList.props.hotelId) + "/by-rooms?" + priceParams.toString();

        let childrenAges = bcl.c.hotelRoomList.props.selectors.childrenAges;
        if (childrenAges && childrenAges.length > 0) {
          childrenAges = childrenAges.map((e) => JSON.parse(e));

          if (childrenAges.includes(null)) {
            return;
          }

          for (const age of childrenAges) {
            pricesUrl += `&children_ages=${age}`;
          }
        }

        const config = { headers: { "X-CBE-Customer-Currency": bcl.s.currency.props.currency.current } };

        fetch(pricesUrl, config)
          .then((response) => response.json())
          .then((res) => {
            if (res.code) {
              bcl.c.hotelRoomList.showErrorRooms();
              return;
            }

            bcl.c.hotelRoomList.initRooms(res.rooms, $rooms);
          })
          .catch((err) => console.error(err));
        bcl.c.hotelRoomList.props.hasFetched = true;
      }
    }

    bcl.c.hotelRoomList.initPagination($rooms);

    if ($rooms.length === 0) {
      bcl.c.hotelRoomList.loadDataInHeadHotel();
    }

    const roomRecommendedContainer = document.querySelector(".hotel-room-recommended-container");
    roomRecommendedContainer?.style.height ? (roomRecommendedContainer.style.height = "500px") : "";

    if (bcl.c.hotelRoomList.handleLoadDataInitComplete) {
      document.removeEventListener("loaded-all-data", bcl.c.hotelRoomList.handleLoadDataInitComplete);
    }

    bcl.c.hotelRoomList.handleLoadDataInitComplete = function () {
      bcl.c.hotelRoomList.handleLoadDataInit($rooms, roomRecommendedContainer);
    };

    document.addEventListener("loaded-all-data", bcl.c.hotelRoomList.handleLoadDataInitComplete);

    bcl.c.hotelRoomPage.props.firstLoad = false;
  },

  cleanArticlesRoomsContainersToSort: function ($rooms) {
    for (const $room of $rooms) {
      bcl.u.removeClass($room, "notAvailableRoom-JS");
      bcl.u.removeClass($room, "notAvailableRoomWithDate-JS");
      $room.dataset.opendatetimestamp = "0";
      $room.dataset.sortordernoavailableroom = "0";
    }
  },

  //Order rooms by : # PLASOFT-12722.
  // 1 - Available rooms with price ( list ).
  // 2 - Not available rooms WITH next available DATE.
  // 3 - Not available rooms WITHOUT next available DATE.
  orderNotAvailableRoomsByPriorities: function () {
    if (bcl.c.hotelRoomList.props.useOldAPI) {
      let $rooms = bcl.c.hotelRoomList.props.$container.querySelectorAll("article.notAvailableRoom-JS");
      $rooms = Array.prototype.slice.call($rooms);
      //Sort not available rooms
      $rooms.sort(function (a, b) {
        const aTimeStamp = a.dataset.opendatetimestamp;
        const bTimeStamp = b.dataset.opendatetimestamp;
        return bTimeStamp - aTimeStamp;
      });
      //Put in back rooms DIVS on container.
      for (let i = 0, len = $rooms.length; i < len; i++) {
        const parent = $rooms[i].parentNode;
        const detatchedItem = parent.removeChild($rooms[i]);
        parent.appendChild(detatchedItem);
      }

      //Now order the the rooms without availabity but have next date
      let $roomsWithDate = bcl.c.hotelRoomList.props.$container.querySelectorAll("article.notAvailableRoomWithDate-JS");
      $roomsWithDate = Array.prototype.slice.call($roomsWithDate);
      //Sort not available rooms
      $roomsWithDate.sort(function (a, b) {
        const aTimeStamp = a.dataset.opendatetimestamp;
        const bTimeStamp = b.dataset.opendatetimestamp;
        return aTimeStamp - bTimeStamp;
      });
      //Put in back rooms DIVS on container.
      for (let z = 0, lenZ = $roomsWithDate.length; z < lenZ; z++) {
        const parent = $roomsWithDate[z].parentNode;
        const detatchedItem = parent.removeChild($roomsWithDate[z]);
        parent.appendChild(detatchedItem);
      }

      //Move rooms without date to bottom list.
      let index = 1;
      const $roomsWithDateToReorder = document.querySelectorAll(".notAvailableRoom-JS.notAvailableRoomWithDate-JS");
      for (const item of $roomsWithDateToReorder) {
        item.dataset.sortordernoavailableroom = index;
        index++;
      }

      const $roomsNODateToReorder = document.querySelectorAll(".notAvailableRoom-JS:not(.notAvailableRoomWithDate-JS)");
      for (const $roomWithDateItem of $roomsNODateToReorder) {
        $roomWithDateItem.dataset.sortordernoavailableroom = index;
        index++;
      }

      //Final reorder all again.
      let $roomsFinal = bcl.c.hotelRoomList.props.$container.querySelectorAll("article.notAvailableRoom-JS");
      $roomsFinal = Array.prototype.slice.call($roomsFinal);
      //Sort not available rooms
      $roomsFinal.sort(function (a, b) {
        const aValue = a.dataset.sortordernoavailableroom;
        const bValue = b.dataset.sortordernoavailableroom;
        return parseInt(aValue) - parseInt(bValue);
      });
      //Put in back rooms DIVS on container.
      for (let z = 0, lenZ = $roomsFinal.length; z < lenZ; z++) {
        const parent = $roomsFinal[z].parentNode;
        const detatchedItem = parent.removeChild($roomsFinal[z]);
        parent.appendChild(detatchedItem);
      }
    } else {
      let $rooms = bcl.c.hotelRoomList.props.$container.querySelectorAll("article.notAvailableRoom-JS");
      $rooms = Array.prototype.slice.call($rooms);
      //Sort not available rooms
      $rooms.sort(function (a, b) {
        const aTimeStamp = a.dataset.opendatetimestamp;
        const bTimeStamp = b.dataset.opendatetimestamp;
        return bTimeStamp - aTimeStamp;
      });
      //Put in back rooms DIVS on container.
      for (let i = 0, len = $rooms.length; i < len; i++) {
        const parent = $rooms[i].parentNode;
        const detatchedItem = parent.removeChild($rooms[i]);
        parent.appendChild(detatchedItem);
      }

      //Now order the the rooms without availabity but have next date
      let $roomsWithDate = bcl.c.hotelRoomList.props.$container.querySelectorAll("article.notAvailableRoomWithDate-JS");
      $roomsWithDate = Array.prototype.slice.call($roomsWithDate);
      //Sort not available rooms
      $roomsWithDate.sort(function (a, b) {
        const aTimeStamp = a.dataset.opendatetimestamp;
        const bTimeStamp = b.dataset.opendatetimestamp;
        return aTimeStamp - bTimeStamp;
      });
      //Put in back rooms DIVS on container.
      for (let z = 0, lenZ = $roomsWithDate.length; z < lenZ; z++) {
        const parent = $roomsWithDate[z].parentNode;
        const detatchedItem = parent.removeChild($roomsWithDate[z]);
        parent.appendChild(detatchedItem);
      }

      //Move rooms without date to bottom list.
      let index = 1;
      const $roomsWithDateToReorder = document.querySelectorAll(".notAvailableRoom-JS.notAvailableRoomWithDate-JS");
      for (const item of $roomsWithDateToReorder) {
        item.dataset.sortordernoavailableroom = index;
        index++;
      }

      const $roomsNODateToReorder = document.querySelectorAll(".c-hotel-room-list-JS:has(.c-hotel-room-list__footer-unavailable:not(.hidden))");
      for (const $roomWithDateItem of $roomsNODateToReorder) {
        $roomWithDateItem.dataset.sortordernoavailableroom = index;
        index++;
      }

      //Final reorder all again.
      let $roomsFinal = bcl.c.hotelRoomList.props.$container.querySelectorAll("article:has(.c-hotel-room-list__footer-unavailable:not(.hidden))");
      $roomsFinal = Array.prototype.slice.call($roomsFinal);
      //Sort not available rooms
      $roomsFinal.sort(function (a, b) {
        const aValue = a.dataset.sortordernoavailableroom;
        const bValue = b.dataset.sortordernoavailableroom;
        return parseInt(aValue) - parseInt(bValue);
      });
      //Put in back rooms DIVS on container.
      for (let z = 0, lenZ = $roomsFinal.length; z < lenZ; z++) {
        const parent = $roomsFinal[z].parentNode;
        const detatchedItem = parent.removeChild($roomsFinal[z]);
        parent.appendChild(detatchedItem);
      }
    }
  },

  unBlurMybPricesIfNeeded: function () {
    bcl.u.loadComponentByName("mybSwitcher");
    setTimeout(function () {
      bcl.c.mybSwitcher?.applyChanges("roomList");
    }, 0);
  },

  showRoomsPopup: function () {
    const roomsFB = document.getElementById("rooms-fb");
    if (roomsFB) {
      setTimeout(function () {
        if (bcl.c.hotelRoomList.props.fastBookingMsgBoxOpened == false) {
          bcl.c.hotelRoomList.props.fastBookingMsgBoxOpened = true;
          bcl.u.triggerEvent(roomsFB, "focusin");
        }
      }, 700);
    }
  },
  showWrongConfigBoxes: function () {
    if (bcl.c.fastbooking) {
      const unavailableBookingButtons = document.querySelectorAll(".c-hotel-room-list__footer .booking-button-JS.hidden"),
        $inputAdults = document.querySelector(".c-fastbooking .input-adult-JS"),
        requestedOccupancy = $inputAdults ? parseInt($inputAdults.value) : 0,
        //booking-button and button-clone-JS
        availabilityExists = bcl.c.hotelRoomList.props.numHotels * 2 > unavailableBookingButtons.length;

      if (unavailableBookingButtons) {
        for (let i = 0; i < unavailableBookingButtons.length; i++) {
          const bookingButton = unavailableBookingButtons[i],
            room = bookingButton.closest("." + bcl.c.hotelRoomList.props.itemCssSelector);

          if (room && room.dataset.occupancy && parseInt(room.dataset.occupancy) === requestedOccupancy && availabilityExists) {
            const wrongConfigMsgBox = document.querySelectorAll("." + bcl.c.hotelRoomList.props.msgBoxWrongConfig),
              availableRoomWithOccupancy = bcl.c.hotelRoomList.getFirstAvailableRoomWithOccupancy(requestedOccupancy);

            if (!availableRoomWithOccupancy) {
              if (wrongConfigMsgBox) {
                bcl.u.forEach(wrongConfigMsgBox, function (elem) {
                  bcl.u.removeClass(elem, "hidden");
                });
              }

              const fastBookingMsgBox = document.querySelectorAll("." + bcl.c.hotelRoomList.props.fastBookingMsgBox);
              if (fastBookingMsgBox) {
                bcl.u.forEach(fastBookingMsgBox, function (elem) {
                  bcl.u.addClass(elem, "hidden");
                });
              }

              bcl.c.hotelRoomList.showRoomsPopup();
              return;
            }
          }
        }
      }
    }
  },
  getFirstAvailableRoomWithOccupancy: function (occupancy) {
    const availableBookingButtons = document.querySelectorAll(".c-hotel-room-list__footer .booking-button-JS:not(.hidden)");
    if (availableBookingButtons) {
      for (let i = 0; i < availableBookingButtons.length; i++) {
        const $room = availableBookingButtons[i].closest("." + bcl.c.hotelRoomList.props.itemCssSelector);
        if ($room && $room.dataset.occupancy && parseInt($room.dataset.occupancy) >= occupancy) {
          return $room;
        }
      }
    }
    return;
  },
  initSelectors: function (startDate, endDate) {
    if (!startDate) {
      startDate = bcl.c.fastbooking.commonProps.calendar.data.startDate;
    } else {
      startDate = bcl.c.fastbooking.calendar.parseDate(startDate);
    }

    if (!endDate) {
      endDate = bcl.c.fastbooking.commonProps.calendar.data.endDate;
    } else {
      endDate = bcl.c.fastbooking.calendar.parseDate(endDate);
    }

    if (startDate && endDate) {
      if (bcl.c.hotelRoomList.props.useOldAPI) {
        bcl.c.hotelRoomList.props.selectors = startDate.replace(/-/gi, "");
        bcl.c.hotelRoomList.props.selectors += "." + endDate.replace(/-/gi, "");
        bcl.c.hotelRoomList.props.selectors += bcl.c.fastbooking.commonProps.rooms.valueAdults ? "." + bcl.c.fastbooking.commonProps.rooms.valueAdults : ".2";
        bcl.c.hotelRoomList.props.selectors += bcl.c.fastbooking.commonProps.rooms.valueChildren ? "." + bcl.c.fastbooking.commonProps.rooms.valueChildren : ".0";
        bcl.c.hotelRoomList.props.selectors += bcl.c.fastbooking.commonProps.rooms.data.rooms ? "." + bcl.c.fastbooking.commonProps.rooms.data.rooms : ".1";
        bcl.c.hotelRoomList.props.selectors += ".json";
      } else {
        bcl.c.hotelRoomList.props.selectors = {};
        bcl.c.hotelRoomList.props.selectors.startDate = startDate;
        bcl.c.hotelRoomList.props.selectors.endDate = endDate;
        bcl.c.hotelRoomList.props.selectors.numberAdults = bcl.c.fastbooking.commonProps.rooms.valueAdults;
        bcl.c.hotelRoomList.props.selectors.numberChildren = bcl.c.fastbooking.commonProps.rooms.valueChildren;
        bcl.c.hotelRoomList.props.selectors.numberRooms = bcl.c.fastbooking.commonProps.rooms.data.rooms;
        bcl.c.hotelRoomList.props.selectors.childrenAges = bcl.c.fastbooking.commonProps.rooms.data.children.flat(1);
      }
    } else {
      if (bcl.c.hotelRoomList.props.useOldAPI) {
        bcl.c.hotelRoomList.props.selectors = "json";
      } else {
        bcl.c.hotelRoomList.props.selectors = {};
        const today = bcl.c.hotelRoomList.getUTCTodayDate();
        const tomorrow = bcl.c.hotelRoomList.getDayFromToday(today, 1);
        bcl.c.hotelRoomList.props.selectors.noSelectors = true;
        bcl.c.hotelRoomList.props.selectors.startDate = bcl.u.formatDate(today);
        bcl.c.hotelRoomList.props.selectors.endDate = bcl.u.formatDate(tomorrow);
        bcl.c.hotelRoomList.props.selectors.numberAdults = 2;
        bcl.c.hotelRoomList.props.selectors.numberChildren = 0;
        bcl.c.hotelRoomList.props.selectors.numberRooms = bcl.c.fastbooking.commonProps.rooms.data.rooms;
      }
    }
  },

  initDateClosing: function () {
    const $messageClosingHotel = document.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.node);

    if (!$messageClosingHotel) {
      return;
    }

    let hotelclosing = $messageClosingHotel.dataset.hotelclosing,
      hotelopening = $messageClosingHotel.dataset.hotelopening;

    bcl.c.hotelRoomList.props.$messageClosingHotel = $messageClosingHotel;

    if (hotelclosing && hotelopening) {
      hotelclosing = new Date(hotelclosing);
      hotelopening = new Date(hotelopening);

      bcl.c.hotelRoomList.props.hotelclosing = hotelclosing;
      bcl.c.hotelRoomList.props.hotelopening = hotelopening;

      const now = new Date();

      if (hotelclosing <= now && now <= hotelopening) {
        const endDate = new Date(hotelopening);

        bcl.c.hotelRoomList.props.nowDateInClosing = true;

        if (!bcl.c.hotelRoomList.props.filterDateFB) {
          endDate.setMonth(endDate.getMonth() + 3);
          bcl.c.hotelRoomList.initSelectors(hotelopening, endDate);
        }
      }
    }
  },

  initNodesClosing: function () {
    if (bcl.c.hotelRoomList.props.filterDateFB) {
      const startDate = new Date(bcl.c.fastbooking.commonProps.calendar.data.startDate),
        endDate = new Date(bcl.c.fastbooking.commonProps.calendar.data.endDate);

      if (endDate > bcl.c.hotelRoomList.props.hotelclosing && startDate < bcl.c.hotelRoomList.props.hotelopening) {
        bcl.c.hotelRoomList.props.nowDateInClosing = true;
      }
    }

    if (bcl.c.hotelRoomList.props.nowDateInClosing) {
      bcl.c.hotelRoomList.processMessageClosingHotel();
    }
  },

  processMessageClosingHotel: function () {
    if (!bcl.c.hotelRoomList.props.$messageClosingHotel) {
      return;
    }

    const $messageClosingHotel = bcl.c.hotelRoomList.props.$messageClosingHotel;

    const $dayClosingHotel = $messageClosingHotel.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.dayClosing);

    const $monthClosingHotel = $messageClosingHotel.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.monthClosing);

    const $dayOpeningHotel = $messageClosingHotel.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.dayOpening);

    const $monthOpeningHotel = $messageClosingHotel.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.monthOpening);

    const $nameHotel = $messageClosingHotel.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.hotelName);

    $dayClosingHotel.innerText = bcl.c.hotelRoomList.props.hotelclosing.getDate();
    $monthClosingHotel.innerText = bcl.c.fastbooking.commonProps.calendar.obj.i18n["month-names"][bcl.c.hotelRoomList.props.hotelclosing.getMonth()];

    $dayOpeningHotel.innerText = bcl.c.hotelRoomList.props.hotelopening.getDate();
    $monthOpeningHotel.innerText = bcl.c.fastbooking.commonProps.calendar.obj.i18n["month-names"][bcl.c.hotelRoomList.props.hotelopening.getMonth()];

    $nameHotel.innerText = $messageClosingHotel.dataset.name;

    bcl.u.removeClass($messageClosingHotel, "invisible");
  },

  initNodePagination: function () {
    bcl.c.hotelRoomList.props.$pagination = bcl.c.hotelRoomList.props.$container.getElementsByClassName(bcl.c.hotelRoomList.props.paginationClassName)[0];

    bcl.c.hotelRoomList.props.numberPagination = bcl.c.hotelRoomList.props.$pagination.dataset.numbershow;

    bcl.c.hotelRoomList.props.$pagination.addEventListener("click", bcl.c.hotelRoomList.clickPagination);
  },

  initPagination: function ($rooms) {
    if ($rooms.length <= bcl.c.hotelRoomList.props.numberPagination) {
      bcl.u.addClass(bcl.c.hotelRoomList.props.$pagination, "invisible");
    }
  },

  clickPagination: function () {
    const $cards = bcl.c.hotelRoomList.props.$container.querySelectorAll("." + bcl.c.hotelRoomList.props.itemCssSelector + "." + bcl.c.hotelRoomList.props.hidePagClassName);
    let length = $cards.length;

    bcl.u.addClass(bcl.c.hotelRoomList.props.$pagination, "invisible");

    while (length != 0) {
      bcl.u.removeClass($cards[length - 1], bcl.c.hotelRoomList.props.hidePagClassName);
      const $card = $cards[length - 1],
        flipCard = $card.querySelector(bcl.c.flipcard.props.selectorFlipCard);
      flipCard && bcl.c.flipcard.init(flipCard);
      length--;
    }

    bcl.u.triggerEvent(window, "cmp-image-global-redraw-all");
  },

  initHead: function () {
    const $container = document.getElementsByClassName(bcl.c.hotelRoomList.props.headSelector);
    let $containerMyb;
    if (bcl.c.hotelRoomList.props.mybPrices === true && bcl.c.hotelRoomList.props.mybPricesContainer) {
      $containerMyb = document.getElementsByClassName("c-head-hotel-info__booking-info-price-myb");
      bcl.u.removeClass($containerMyb[0], "hidden");
      const $pushBannerCheckBox = document.getElementsByClassName("c-myb-push-banner-JS");
      if ($pushBannerCheckBox.length > 0) {
        bcl.u.removeClass($pushBannerCheckBox[0], "hidden");
        bcl.c.mybSwitcher?.searchNodesForClose($pushBannerCheckBox[0], bcl.c.mybSwitcher?.clickClose.bind(bcl.c.mybSwitcher));
      }

      const $triggerBanners = document.getElementsByClassName("c-myb-trigger-banner-JS");
      bcl.u.forEach($triggerBanners, function ($triggerBanner) {
        bcl.c.mybTriggerBanner?.bannerSwitcher($triggerBanner);
      });
    }

    if (!$container || !$container[0]) {
      bcl.c.hotelRoomPage.initHeadRoomPage();
      return;
    }

    bcl.c.hotelRoomList.props.$head = $container[0];
    if (bcl.c.hotelRoomList.props.mybPrices === true && bcl.c.hotelRoomList.props.mybPricesContainer) {
      bcl.c.hotelRoomList.props.$headMyb = $containerMyb[0];
    }

    const useOldAPI = bcl.c.hotelRoomList.props.useOldAPI;
    const noSelectors = (bcl.c.hotelRoomList.props.selectors.noSelectors && !useOldAPI) || (useOldAPI && bcl.c.hotelRoomList.props.selectors == "json");

    const $priceDom = bcl.c.hotelRoomList.props.$head.querySelector(".c-price__value");
    if ($priceDom && !noSelectors && bcl.c.hotelRoomList.props.firstLoad) {
      bcl.c.hotelRoomList.props.priceDom = currencyShared.parseTextPrice($priceDom.textContent);
    }

    bcl.u.addClass(bcl.c.hotelRoomList.props.$head.children[0], "invisible");
    bcl.u.addClass(bcl.c.hotelRoomList.props.$head.children[1], "invisible");

    bcl.u.addSpinner($container[0]);
  },

  initRoom: function ($node, index, roomData) {
    const $room = $node.querySelector("." + bcl.c.hotelRoomList.props.elementSelector);

    bcl.u.addSpinner($room);

    bcl.c.hotelRoomList.props.count++;

    if (bcl.c.hotelRoomList.props.useOldAPI) {
      let url = $room.getAttribute("data-url");
      url = bcl.u.includes(url, "json") ? url.replace(".json", "." + bcl.s.currency.props.currency.current + ".json") : url;
      if (bcl.c.hotelRoomList.props.selectors !== "json") {
        url = url.replace("json", bcl.c.hotelRoomList.props.selectors);
        bcl.ajax.postRequest({
          url: url,
          callback: bcl.c.hotelRoomList.loadRoomsPrice,
          itemCallback: $room,
          errorCallback: bcl.c.hotelRoomList.loadError,
        });
      } else {
        url = url.replace("json", "html");
        bcl.ajax.getRequest(null, url, bcl.c.hotelRoomList.loadRoomsPrice, $room, null, bcl.c.hotelRoomList.loadError);
      }
    } else {
      bcl.c.hotelRoomList.loadRoomsPrice(roomData, $node);
    }

    if (index >= bcl.c.hotelRoomList.props.numberPagination) {
      bcl.u.addClass($node, bcl.c.hotelRoomList.props.hidePagClassName);
    }
  },

  initRooms: function (rooms, $rooms) {
    Array.prototype.slice.call($rooms).forEach(function (value, index) {
      const roomData = rooms.find((e) => e.id == value.dataset.roomcode);

      if (roomData && roomData.rates?.average?.total) {
        const totalWithTaxes = roomData.rates.average.total;
        if (totalWithTaxes.converted) {
          roomData.stdPrice = bcl.u.calculateDecimalsByExponent(totalWithTaxes.converted.value, totalWithTaxes.converted.exponent);
        } else {
          roomData.stdPrice = bcl.u.calculateDecimalsByExponent(totalWithTaxes.value, totalWithTaxes.exponent);
        }
      }

      bcl.c.hotelRoomList.initRoom(value, index, roomData);
      bcl.c.hotelRoomList.emittedEvents++;
    });
  },

  loadError: function ($el) {
    const headRoom = bcl.u.containsClass($el, "c-header-detail__booking");
    bcl.c.hotelRoomList.notDataPrice($el, headRoom);
  },

  showErrorRooms: function () {
    const $rooms = bcl.c.hotelRoomList.props.$container.getElementsByClassName(bcl.c.hotelRoomList.props.itemCssSelector);
    for (const room of Array.from($rooms)) {
      bcl.c.hotelRoomList.loadError(room);
    }
  },

  loadRoomsPriceByGet: function (res, $el) {
    if (bcl.c.hotelRoomPage.props.firstLoad) {
      bcl.c.hotelRoomPage.props.firstLoad = false;
      bcl.c.hotelRoomPage.updateUrl();
    } else {
      bcl.c.hotelRoomList.loadRoomsPrice(res, $el, true);
    }
    bcl.u.removeSpinner($el);
  },

  checkMinStay: function (data) {
    if (!data || !data.stdPrice || typeof data.stdPrice.minStay === "undefined" || data.stdPrice.minStay == 0) {
      return true;
    }
    return parseInt(data.stdPrice.minStay) <= 0;
  },

  checkLoadStatus: function () {
    bcl.c.hotelRoomList.receivedEvents++;

    if (bcl.c.hotelRoomList.emittedEvents == bcl.c.hotelRoomList.receivedEvents) {
      document.dispatchEvent(new CustomEvent("hotel-room-list-ready"));
    }
  },

  loadRoomsPrice: function (res, $el, roompage) {
    if (bcl.editMode) {
      return;
    }

    const promise = new Promise((resolve) => {
      const countdown = setInterval(() => {
        bcl.c.hotelRoomList.props.count--;
        clearInterval(countdown);
        resolve();
      }, 1000);
    });

    promise
      .then(() => {
        if (bcl.c.hotelRoomList.props.useOldAPI) {
          const headRoom = bcl.u.containsClass($el, "c-header-detail__booking");

          if (!res || !res.response) {
            bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);
            bcl.c.hotelRoomList.checkLoadStatus();
            return;
          }

          const data = JSON.parse(res.response);
          let status = true,
            printMybFooter = false;

          if (!bcl.c.hotelRoomList.props.$messages) {
            bcl.c.hotelRoomList.props.$messages = document.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.node);
          }

          if (data && !data.errorApi) {
            if (!data.stdPrice) {
              bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }

            bcl.s.currency.evaluateRelativeMarketRate(data);

            bcl.c.hotelRoomList.props.price = 10000000000;
            bcl.c.hotelRoomList.props.priceMyb = 10000000000;

            if (!bcl.c.hotelRoomList.checkMinStay(data)) {
              bcl.c.hotelRoomList.props.hotelFinallyClosed = false;
              bcl.c.hotelRoomList.noAvailable($el, "data-min-stay-message", roompage);
              bcl.c.hotelRoomList.checkIndexPromise();
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }

            if (data.stdPrice.closed && data.stdPrice.availableFrom) {
              bcl.c.hotelRoomList.availableFrom($el, data.stdPrice.availableFrom, roompage);
              bcl.c.hotelRoomList.props.hotelFinallyClosed = false;
              bcl.c.hotelRoomList.checkIndexPromise();
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }

            if (data.stdPrice.closed) {
              bcl.c.hotelRoomList.noAvailable($el, "data-no-available");
              bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);
              bcl.c.hotelRoomList.checkIndexPromise();
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }

            if (data.stdPrice.restrinction) {
              const errorShow = bcl.c.hotelRoomList.restrinctionCases(data.stdPrice.restrinction);
              bcl.c.hotelRoomList.props.hotelFinallyClosed = false;
              bcl.c.hotelRoomList.noAvailable($el, errorShow, roompage);
              bcl.c.hotelRoomList.checkIndexPromise();
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }
            status = bcl.c.hotelRoomList.setMinimumPrice($el, data);
            printMybFooter = bcl.c.hotelRoomList.setMybFooterPriceOnRoomCard($el, data);

            if (headRoom) {
              const $priceDom = $el.querySelector(".c-price__value");

              if ($priceDom) {
                const price = $priceDom.dataset.marketPrice || $priceDom.innerText;

                bcl.u.utag.add({
                  booking_price: price,
                  booking_price_exchange: $priceDom.innerText,
                  product_price: [price],
                });
              }

              bcl.u.utag.removeListener("hotel-room-list");
            }
          } else {
            bcl.c.hotelRoomList.setNoPrice($el);
          }

          bcl.c.hotelRoomList.setStatus($el, status, roompage, printMybFooter);
          bcl.c.hotelRoomList.checkIndexPromise();

          bcl.c.hotelRoomList.checkLoadStatus();
        } else {
          const headRoom = bcl.u.containsClass($el, "c-header-detail__booking");

          if (!res) {
            bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);
            bcl.c.hotelRoomList.checkLoadStatus();
            return;
          }

          let status = true,
            printMybFooter = false;

          if (!bcl.c.hotelRoomList.props.$messages) {
            bcl.c.hotelRoomList.props.$messages = document.querySelector("." + bcl.c.hotelRoomList.props.closingHotelSelector.node);
          }

          if (res && !res.errorApi) {
            if (res.rates == {} || !res.rates || !res.rates?.average?.base || !res.rates?.average?.total) {
              bcl.c.hotelRoomList.noAvailable($el, "data-no-available");
              bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }

            bcl.c.hotelRoomList.props.price = 10000000000;
            bcl.c.hotelRoomList.props.priceMyb = 10000000000;

            if (res.restriction.status != bcl.c.hotelRoomList.props.restrictions.bconnect.open) {
              const errorShow = bcl.c.hotelRoomList.restrinctionCases(res.restriction.status);

              bcl.c.hotelRoomList.noAvailable($el, errorShow);
              bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);

              // TODO: IMPLEMENT WHEN SYNCHRO IS READY
              // if(availableFrom) {
              //   const untilDate = bcl.c.hotelRoomList.getDayFromToday(res.restriction.quantity);
              //   const dateObj = {
              //     day: untilDate.getDate(),
              //     month: untilDate.getMonth() + 1,
              //     year: untilDate.getFullYear(),
              //   };
              //   bcl.c.hotelRoomList.availableFrom($el, dateObj, roompage);
              //   bcl.c.hotelRoomList.props.hotelFinallyClosed = false;
              // }

              bcl.c.hotelRoomList.checkIndexPromise();
              bcl.c.hotelRoomList.checkLoadStatus();

              return;
            }
            if (res.minStay) {
              const errorShow = bcl.c.hotelRoomList.restrinctionCases(bcl.c.hotelRoomList.props.restrictions.bconnect.minStay);
              bcl.c.hotelRoomList.noAvailable($el, errorShow);
              bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);
              bcl.c.hotelRoomList.checkIndexPromise();
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }
            if (res.noArrival) {
              const errorShow = bcl.c.hotelRoomList.restrinctionCases(bcl.c.hotelRoomList.props.restrictions.bconnect.noArrival);
              bcl.c.hotelRoomList.noAvailable($el, errorShow);
              bcl.c.hotelRoomList.notDataPrice($el, headRoom, roompage);
              bcl.c.hotelRoomList.checkIndexPromise();
              bcl.c.hotelRoomList.checkLoadStatus();
              return;
            }

            status = bcl.c.hotelRoomList.setMinimumPrice($el, res);
            printMybFooter = bcl.c.hotelRoomList.setMybFooterPriceOnRoomCard($el, res);

            if (headRoom) {
              const $priceDom = $el.querySelector(".c-price__value");

              if ($priceDom) {
                const price = $priceDom.dataset.marketPrice || $priceDom.innerText;

                bcl.u.utag.add({
                  booking_price: price,
                  booking_price_exchange: $priceDom.innerText,
                  product_price: [price],
                });
              }

              bcl.u.utag.removeListener("hotel-room-list");
            }
          } else {
            bcl.c.hotelRoomList.setNoPrice($el);
          }

          bcl.c.hotelRoomList.setStatus($el, status, roompage, printMybFooter);
          bcl.c.hotelRoomList.checkIndexPromise();

          bcl.c.hotelRoomList.checkLoadStatus();
        }
      })
      .catch(function (reason) {
        console.error("Error en promesa (" + reason + ")");
      });
  },

  restrinctionCases: function (restriction) {
    if (bcl.c.hotelRoomList.props.useOldAPI) {
      switch (String(restriction)) {
        case bcl.c.hotelRoomList.props.restrictions.sabre.maxAdultOccupancyExceeded:
          return "data-restrinction";
        case bcl.c.hotelRoomList.props.restrictions.sabre.maxChildOccupancyExceeded:
          return "data-restrinctionChild";
        case bcl.c.hotelRoomList.props.restrictions.sabre.noAvailableInventory:
          return "data-no-available";
        default:
          return "data-no-available";
      }
    } else {
      switch (String(restriction)) {
        case bcl.c.hotelRoomList.props.restrictions.bconnect.maxAdultOccupancyExceeded:
          return "data-restrinction";
        case bcl.c.hotelRoomList.props.restrictions.bconnect.maxChildOccupancyExceeded:
          return "data-restrinctionChild";
        case bcl.c.hotelRoomList.props.restrictions.bconnect.closed:
          return "data-no-available";
        case bcl.c.hotelRoomList.props.restrictions.bconnect.minStay:
          return "data-min-stay-message";
        case bcl.c.hotelRoomList.props.restrictions.bconnect.maxStay:
          return "data-no-available";
        case bcl.c.hotelRoomList.props.restrictions.bconnect.leadDays:
          return "data-no-available";
        case bcl.c.hotelRoomList.props.restrictions.bconnect.noArrival:
          return "data-no-available";
        case bcl.c.hotelRoomList.props.restrictions.bconnect.noDeparture:
          return "data-no-available";
        default:
          return "data-no-available";
      }
    }
  },

  checkIndexPromise() {
    if (bcl.c.hotelRoomList.props.indexToPromise === bcl.c.hotelRoomList.props.numHotels - 1) {
      document.dispatchEvent(new CustomEvent("loaded-all-data"));
    } else {
      bcl.c.hotelRoomList.props.indexToPromise++;
    }
  },

  noAvailable: function ($el, selector, roompage) {
    const message = bcl.c.hotelRoomList.props.$messages.getAttribute(selector);
    bcl.c.hotelRoomList.writeMessage($el, message);
    if (bcl.c.hotelRoomList.props.useOldAPI) {
      bcl.u.removeSpinner($el);
    } else {
      bcl.u.removeSpinner($el.querySelector(bcl.c.hotelRoomList.props.footerSelector));
    }
    if (roompage) {
      bcl.c.hotelRoomPage.showUnavailable($el, bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    } else {
      bcl.c.hotelRoomList.showUnavailable($el, bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    }
  },

  hideMessageContainer: function () {
    const container = document.querySelector(".c-header-detail__message-JS");
    if (container) {
      bcl.u.addClass(container, "hidden");
    }
  },

  availableFrom: function ($el, availableFrom, roompage) {
    bcl.u.addClass($el, bcl.c.hotelRoomList.props.classAvailableFrom);
    const info = bcl.c.hotelRoomList.setOpenDateRoom($el, availableFrom);

    const openDateTimeStamp = bcl.c.hotelRoomList.getOpenDateTimeStamp(availableFrom);
    if (openDateTimeStamp) {
      const $articleRoom = bcl.u.closest($el, bcl.c.hotelRoomList.props.hotelRoomSelector);
      if ($articleRoom) {
        $articleRoom.dataset.opendatetimestamp = openDateTimeStamp;
        bcl.u.addClass($articleRoom, "notAvailableRoom-JS");
        bcl.u.addClass($articleRoom, "notAvailableRoomWithDate-JS");
      }
    }
    if (info.message == undefined || info.message === "") {
      bcl.c.hotelRoomList.hideMessageContainer();
    }

    //check here if availableFrom.day start with 0 or 00 , remove it
    let correctDayToMsg = "";
    if (availableFrom.day && availableFrom.day.length == 2 && availableFrom.day.startsWith("0")) {
      correctDayToMsg = availableFrom.day.split("0")[1];
    } else if (availableFrom.day && availableFrom.day.length == 3 && availableFrom.day.startsWith("00")) {
      correctDayToMsg = availableFrom.day.split("00")[1];
    } else {
      correctDayToMsg = availableFrom.day;
    }

    bcl.c.hotelRoomList.writeMessage($el, info.message + " " + correctDayToMsg + " " + info.month);
    bcl.c.hotelRoomList.setButtonNewListener($el);
    if (bcl.c.hotelRoomList.props.useOldAPI) {
      bcl.u.removeSpinner($el);
    } else {
      bcl.u.removeSpinner($el.querySelector(bcl.c.hotelRoomList.props.footerSelector));
    }

    if (roompage) {
      bcl.c.hotelRoomPage.showUnavailable($el, bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    } else {
      bcl.c.hotelRoomList.showUnavailable($el, bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    }
  },

  setButtonNewListener($el) {
    const $buttonClone = $el.querySelector(".button-clone-JS");
    if ($buttonClone) {
      const $buttonCloned = $buttonClone.parentNode.querySelector(".button-clone-JS").cloneNode(true);
      $buttonClone.parentNode.replaceChild($buttonCloned, $buttonClone);
      $buttonCloned.addEventListener("click", bcl.c.hotelRoomList.goToFastbooking);
    }
  },

  setOpenDateRoom($item, availableFrom) {
    if (availableFrom.month && availableFrom.month <= 9) {
      availableFrom.month = "0" + availableFrom.month;
    }
    if (availableFrom.day && availableFrom.day <= 9) {
      availableFrom.day = "0" + availableFrom.day;
    }

    bcl.c.hotelRoomList.props.rooms[$item.dataset.roomcode] = availableFrom.year + "-" + availableFrom.month + "-" + availableFrom.day;

    if (!bcl.c.hotelRoomList.props.dateOpen || bcl.c.hotelRoomList.props.dateOpen.getMonth() < availableFrom.month) {
      bcl.c.hotelRoomList.props.dateOpen = new Date(availableFrom.year + "-" + availableFrom.month + "-" + availableFrom.day);
    }
    const month = this.getArrayMonth();
    return {
      message: bcl.c.hotelRoomList.props.$messages.getAttribute("data-current-closed"),
      month: month[availableFrom.month - 1],
    };
  },
  getArrayMonth: function () {
    const month = bcl.c.hotelRoomList.props.$messages.dataset.months;
    if (month.includes(",")) {
      return month.split(",");
    }
    //Check for Arabic comma
    if (month.includes("،")) {
      return month.split("،");
    }
  },
  getOpenDateTimeStamp: function (availableFrom) {
    if (availableFrom.month && availableFrom.month <= 9) {
      availableFrom.month = "0" + availableFrom.month;
    }
    if (availableFrom.day && availableFrom.day <= 9) {
      availableFrom.day = "0" + availableFrom.day;
    }

    const date = new Date();
    date.setMonth(availableFrom.month - 1);
    date.setFullYear(availableFrom.year);
    date.setDate(availableFrom.day);
    return date.getTime();
  },

  getDayFromToday(date, days) {
    const dateObj = new Date();
    dateObj.setDate(date.getDate() + parseInt(days));
    return dateObj;
  },

  getUTCTodayDate() {
    const dateObj = new Date();
    const utcDateObj = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
    return utcDateObj;
  },

  goToFastbooking(evt) {
    evt.stopPropagation();
    const button = evt.target.closest(".booking-button-JS");
    const date = bcl.c.hotelRoomList.props.rooms[button.dataset.roomcode];
    const dateOpen = new Date(date || null);
    bcl.c.fastbooking.calendar.goToDate(dateOpen);
    bcl.c.fastbooking.afterSetData();
    bcl.c.fastbooking.calendar.showOrHideRelateds();
  },

  writeMessage: function ($el, message) {
    if (!message) {
      bcl.c.hotelRoomList.hideMessageContainer();
    }
    const noAvailable = $el.querySelector(".unavailable-content");
    if (noAvailable) {
      noAvailable.textContent = message;
      if (bcl?.s?.translateOnTheFly?.hasToTranslate()) {
        bcl.s.translateOnTheFly?.wrapper(noAvailable);
      }
    }
  },

  setStatus: function ($el, status, roompage) {
    if (roompage) {
      bcl.c.hotelRoomPage.showOrHideLayout(bcl.c.hotelRoomList.props.removeClassName);
      if (bcl.c.hotelRoomList.props.useOldAPI) {
        bcl.u.removeSpinner($el);
      } else {
        bcl.u.removeSpinner($el.querySelector(bcl.c.hotelRoomList.props.footerSelector));
      }
      return;
    }

    if (status) {
      bcl.c.hotelRoomList.showMybFooterPrice($el);
      const $image = $el.parentNode.querySelector(".c-container-image");
      bcl.u.removeClass($image, "mod--img-disabled");
    } else {
      bcl.c.hotelRoomList.showUnavailable($el, bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    }

    if (bcl.c.hotelRoomList.props.useOldAPI) {
      bcl.u.removeSpinner($el);
    } else {
      bcl.u.removeSpinner($el.querySelector(bcl.c.hotelRoomList.props.footerSelector));
    }
  },
  showMybFooterPrice: function ($el) {
    bcl.u.removeClass($el.querySelector(bcl.c.hotelRoomList.props.mybFooterSelector), "hidden");
    bcl.u.removeClass($el.querySelector(bcl.c.hotelRoomList.props.bookingButtonSelector), "hidden");
  },
  hideMybFooterPrice: function ($el) {
    bcl.u.addClass($el.querySelector(bcl.c.hotelRoomList.props.mybFooterSelector), "hidden");
    bcl.u.addClass($el.querySelector(bcl.c.hotelRoomList.props.bookingButtonSelector), "hidden");
  },
  showDefaultFooterPrice: function ($el) {
    bcl.u.removeClass($el.querySelector(bcl.c.hotelRoomList.props.footerPriceSelector), "c-price-normal-myb");
    bcl.u.removeClass($el.querySelector(bcl.c.hotelRoomList.props.footerPriceSelector), "hidden");
    bcl.u.removeClass($el.querySelector(bcl.c.hotelRoomList.props.bookingButtonSelector), "hidden");
    const $mybIcon = $el.querySelector(bcl.c.hotelRoomList.props.mybIconSelector);
    if ($mybIcon) {
      const tooltipClass = $mybIcon.dataset["tooltip"];
      if (tooltipClass.length > 0 && document.querySelector(".c-popover." + tooltipClass)) {
        bcl.u.removeClass($mybIcon, "hidden");
      }
    }
  },
  hideDefaultFooterPrice: function ($el) {
    bcl.u.addClass($el.querySelector(bcl.c.hotelRoomList.props.footerPriceSelector), "c-price-normal-myb");
    bcl.u.removeClass($el.querySelector(bcl.c.hotelRoomList.props.footerPriceSelector), "hidden");
    bcl.u.addClass($el.querySelector(bcl.c.hotelRoomList.props.bookingButtonSelector), "hidden");
  },
  showUnavailable: function ($el, firstMethod, secondaryMethod) {
    const $articleRoom = bcl.u.closest($el, bcl.c.hotelRoomList.props.hotelRoomSelector);
    bcl.u.addClass($articleRoom, "notAvailableRoom-JS");
    if (!$el) {
      return;
    }
    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomList.props.footerPriceSelector), "hidden");
    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomList.props.bookingButtonSelector), "hidden");
    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomList.props.mybFooterSelector), "hidden");

    bcl.u[secondaryMethod]($el.querySelector(bcl.c.hotelRoomList.props.footerUnavailableSelector), "hidden");

    let $image;
    if (bcl.c.hotelRoomList.props.useOldAPI) {
      $image = $el.parentNode.querySelector(".c-container-image");
    } else {
      $image = $el.querySelector(".c-container-image");
    }

    if (bcl.u.containsClass($el, bcl.c.hotelRoomList.props.classAvailableFrom)) {
      bcl.u[secondaryMethod]($el.querySelector(".button-clone-JS"), "hidden");
      bcl.u[secondaryMethod]($image, "mod--img-disabled");
      return;
    }

    bcl.u[firstMethod]($image, "mod--img-disabled");
  },

  setNoPrice: function ($el) {
    const footerPrice = $el.querySelector(".c-hotel-room-list__footer-price");
    bcl.u.addClass(footerPrice, "hidden");

    const cta = $el.querySelector(".booking-button-JS");

    const href = $el.closest("article").querySelector(".c-hotel-room-list__body-title").href;

    //Borrar el listener
    const ctaClone = cta.cloneNode(true);
    cta.parentNode.replaceChild(ctaClone, cta);
    ctaClone.children[0].setAttribute("href", href);

    const messsage = bcl.c.hotelRoomList.props.$messages.dataset.roomDetail;
    ctaClone.children[0].textContent = messsage;
  },

  notDataPrice: function ($el, headRoom, roompage) {
    if (headRoom) {
      bcl.u.utag.removeListener("hotel-room-list");
    }

    if (bcl.c.hotelRoomList.props.useOldAPI) {
      bcl.u.removeSpinner($el);
    } else {
      bcl.u.removeSpinner($el.querySelector(bcl.c.hotelRoomList.props.footerSelector));
    }

    if (roompage) {
      bcl.c.hotelRoomPage.showUnavailable($el, bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    } else {
      bcl.c.hotelRoomList.showUnavailable($el, bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    }

    //Si viene con error ya se resuelve aqui
    if (bcl.c.hotelRoomList.props.indexToPromise === bcl.c.hotelRoomList.props.numHotels - 1) {
      bcl.c.hotelRoomList.hotelFinallyClosed();
      document.dispatchEvent(new CustomEvent("loaded-all-data"));
    } else {
      bcl.c.hotelRoomList.props.indexToPromise++;
    }
  },

  hotelFinallyClosed: function () {
    if (bcl.c.hotelRoomList.props.hotelFinallyClosed) {
      bcl.c.hotelRoomList.props.hotelFinallyClosed = false;
      bcl.c.hotelRoomList.hideZeroPrice();
    }
  },
  setMinimumPrice: function ($el, data) {
    if (!data || !$el) {
      return;
    }

    const $price = $el.querySelector(".c-price__value"),
      $priceMyb = $el.querySelector(bcl.c.hotelRoomList.props.mybPriceSelector);

    if (!bcl.c.hotelRoomList.props.useOldAPI) {
      if (!data.stdPrice || data.stdPrice == 0) {
        return false;
      }

      bcl.c.hotelRoomList.props.dataPrices.push({
        node: $el.parentNode,
        order: parseInt($el.dataset.sortorder),
        value: data.stdPrice,
        valueMyb: data.stdPrice,
      });

      if ($price) {
        $price.innerText = data.stdPrice;
        $price.dataset.marketPrice = data.stdPrice;
        bcl.c.hotelRoomList.props.price = data.stdPrice;
      }

      if ($priceMyb) {
        $priceMyb.innerText = data.stdPrice;
        $priceMyb.dataset.marketPrice = data.stdPrice;
        bcl.c.hotelRoomList.props.priceMyb = data.stdPrice;
      }

      return true;
    } else {
      let valueMyb, value, isZeroValue, valLoyaltyPrice, valStdPrice;

      if (bcl.c.hotelRoomList.props.mybPrices === false) {
        if (data.loyaltyPrice && data.stdPrice) {
          valLoyaltyPrice = data.loyaltyPrice.Amount || data.loyaltyPrice.AmountWithTaxesFees;
          valStdPrice = data.stdPrice.Amount || data.stdPrice.AmountWithTaxesFees;
          if (valLoyaltyPrice > valStdPrice) {
            value = valStdPrice;
          } else if (valLoyaltyPrice === 0) {
            value = valStdPrice;
          } else {
            value = valLoyaltyPrice;
          }
        } else if (data.loyaltyPrice) {
          value = data.loyaltyPrice.Amount || data.loyaltyPrice.AmountWithTaxesFees;
        } else if (data.stdPrice) {
          value = data.stdPrice.Amount || data.stdPrice.AmountWithTaxesFees;
        }

        isZeroValue = value === 0;
        valueMyb = 0;
      } else {
        valLoyaltyPrice = data.loyaltyPrice.Amount || data.loyaltyPrice.AmountWithTaxesFees;
        valStdPrice = data.stdPrice.Amount || data.stdPrice.AmountWithTaxesFees;
        valueMyb = valLoyaltyPrice;
        value = valStdPrice;
        isZeroValue = value === 0 && valueMyb === 0;
      }

      if (bcl.c.hotelRoomList.props.mybPrices === false) {
        if (!value) {
          return;
        }
      }

      if (isZeroValue) {
        return false;
      }

      value = Math.ceil(value);
      valueMyb = Math.ceil(valueMyb);

      if (bcl.c.hotelRoomList.props.price >= value) {
        bcl.c.hotelRoomList.props.price = value;
      }

      if (bcl.c.hotelRoomList.props.priceMyb >= valueMyb) {
        bcl.c.hotelRoomList.props.priceMyb = valueMyb;
        if (document.body.dataset.currency === data.relativeRates.currency) {
          bcl.c.hotelRoomList.props.priceMybDom = bcl.c.hotelRoomList.props.priceMyb;
        } else {
          const rate = data.relativeRates.ratesMap[document.body.dataset.currency].rate;
          bcl.c.hotelRoomList.props.priceMybDom = bcl.c.hotelRoomList.props.priceMyb * rate;
        }
      }

      value = value.toString();
      valueMyb = valueMyb.toString();

      bcl.c.hotelRoomList.props.dataPrices.push({
        node: $el.parentNode,
        order: parseInt($el.parentNode.dataset.sortorder),
        value: parseFloat(value.split(".").join("")),
        valueMyb: parseFloat(valueMyb.split(".").join("")),
      });

      const rate = data.relativeRates.ratesMap[document.body.dataset.currency].rate;
      if (value != 0) {
        $price.dataset.relativeprice = value;
        if (document.body.dataset.currency === data.relativeRates.currency) {
          $price.dataset.marketPrice = value;
        } else {
          $price.dataset.marketPrice = Math.ceil(value * rate);
        }

        $price.innerText = bcl.s.currency.formatPrice($price.dataset.relativeprice);
      }

      //myb
      if (valueMyb != 0) {
        $priceMyb.dataset.relativeprice = valueMyb;
        if (document.body.dataset.currency === data.relativeRates.currency) {
          $priceMyb.dataset.marketPrice = valueMyb;
        } else {
          $priceMyb.dataset.marketPrice = Math.ceil(valueMyb * rate);
        }
        $priceMyb.innerText = bcl.s.currency.formatPrice($priceMyb.dataset.relativeprice);
      }

      return true;
    }
  },

  setMybFooterPriceOnRoomCard: function ($el, roomData) {
    if (bcl.c.hotelRoomList.props.useOldAPI) {
      if (!roomData || !$el) {
        return false;
      }

      const $price = $el.querySelector(bcl.c.hotelRoomList.props.mybFooterSelector),
        ownCurrency = roomData.relativeRates?.currency,
        rate = roomData.relativeRates && roomData.relativeRates.ratesMap ? roomData.relativeRates.ratesMap[document.body.dataset.currency]?.rate : null;

      if (roomData.loyaltyPrice && roomData.stdPrice && bcl.c.hotelRoomList.props.mybPrices && bcl.c.hotelRoomList.props.mybPricesContainer) {
        const valLoyaltyPrice = roomData.loyaltyPrice.Amount || roomData.loyaltyPrice.AmountWithTaxesFees,
          valStdPrice = roomData.stdPrice.Amount || roomData.stdPrice.AmountWithTaxesFees;

        if (valLoyaltyPrice < valStdPrice && valLoyaltyPrice > 0) {
          const $mybPrice = $price.querySelector(bcl.c.hotelRoomList.props.mybPriceSelector);
          if ($mybPrice) {
            $mybPrice.innerHTML = valLoyaltyPrice;
            if (!$mybPrice.dataset.marketPrice) {
              if (document.body.dataset.currency === ownCurrency) {
                $mybPrice.dataset.marketPrice = valLoyaltyPrice;
              } else if (rate) {
                $mybPrice.dataset.marketPrice = Math.ceil(valLoyaltyPrice * rate);
              }
            }
          }

          const $strikePrice = $el.querySelector(bcl.c.hotelRoomList.props.strikePriceSelector + " " + bcl.c.hotelRoomList.props.priceSelector);
          if ($strikePrice) {
            $strikePrice.innerHTML = valStdPrice;
            if (!$strikePrice.dataset.marketPrice) {
              if (document.body.dataset.currency === ownCurrency) {
                $strikePrice.dataset.marketPrice = valStdPrice;
              } else if (rate) {
                $strikePrice.dataset.marketPrice = Math.ceil(valStdPrice * rate);
              }
            }
          }
          return true;
        }
      }
      return false;
    } else {
      if (!roomData || !$el) {
        return false;
      }

      const valStdPrice = roomData.stdPrice;

      const $price = $el.querySelector(bcl.c.hotelRoomList.props.mybFooterSelector);

      const $mybPrice = $price.querySelector(bcl.c.hotelRoomList.props.mybPriceSelector);
      if ($mybPrice) {
        $mybPrice.innerHTML = valStdPrice;
        $mybPrice.dataset.marketPrice = valStdPrice;
        return true;
      }

      const $strikePrice = $el.querySelector(bcl.c.hotelRoomList.props.strikePriceSelector + " " + bcl.c.hotelRoomList.props.priceSelector);
      if ($strikePrice) {
        $strikePrice.innerHTML = valStdPrice;
        $strikePrice.dataset.marketPrice = valStdPrice;
        return true;
      }

      return false;
    }
  },
  loadDataInHeadHotel: function () {
    if (!bcl.c.hotelRoomList.props.$head) {
      bcl.c.hotelRoomPage.loadDataInHeadHotelRoom();
      return;
    }

    bcl.c.hotelRoomList.props.$headPrice = bcl.c.hotelRoomList.props.$head.querySelector(bcl.c.hotelRoomList.props.priceSelector);
    if (bcl.c.hotelRoomList.props.mybPrices === true && bcl.c.hotelRoomList.props.mybPricesContainer) {
      bcl.c.hotelRoomList.props.$headPriceMyb = bcl.c.hotelRoomList.props.$headMyb.querySelector(bcl.c.hotelRoomList.props.mybPriceSelector);
    }

    if (bcl.c.hotelRoomList.props.price != 10000000000) {
      //normal
      if (document.body.dataset.currency === bcl.s.currency.props.currency.current) {
        bcl.c.hotelRoomList.props.$headPrice.dataset.marketPrice = bcl.c.hotelRoomList.props.price;
      } else if (bcl.s.currency.props.relativeRatesMap) {
        const rate = bcl.s.currency.props.relativeRatesMap[document.body.dataset.currency].rate;
        bcl.c.hotelRoomList.props.$headPrice.dataset.marketPrice = Math.ceil(bcl.c.hotelRoomList.props.price * rate);
      }
      bcl.s.currency.toggleUnavailablePrice(bcl.c.hotelRoomList.props.$headPrice);
      bcl.c.hotelRoomList.props.$headPrice.dataset.relativeprice = bcl.c.hotelRoomList.props.price;
      bcl.c.hotelRoomList.props.$headPrice.innerText = bcl.s.currency.formatPrice(bcl.c.hotelRoomList.props.$headPrice.dataset.relativeprice);
      !bcl.s.currency.props.isRelativePriceRequest && bcl.s.currency.convertPrices(bcl.c.hotelRoomList.props.$head);
    } else {
      if (bcl.c.hotelRoomList.props.priceDom !== 0 && bcl.c.hotelRoomList.props.priceDom !== 10000000000) {
        bcl.c.hotelRoomList.props.$headPrice.innerText = bcl.s.currency.formatPrice(bcl.c.hotelRoomList.props.priceDom);
        bcl.c.hotelRoomList.props.$headPrice.dataset.marketPrice = bcl.c.hotelRoomList.props.priceDom;
        if (bcl.s.currency.props.isRelativePriceRequest) {
          bcl.c.hotelRoomList.props.$headPrice.dataset.relativeprice = bcl.c.hotelRoomList.props.priceDom;
        }
      }
    }

    if (bcl.c.hotelRoomList.props.priceMyb != 10000000000 && bcl.c.hotelRoomList.props.mybPrices === true && bcl.c.hotelRoomList.props.$headPriceMyb && bcl.c.hotelRoomList.props.mybPricesContainer) {
      //my barcelo

      bcl.u.containsClass(bcl.c.hotelRoomList.props.$headPriceMyb, "hidden") && bcl.u.removeClass(bcl.c.hotelRoomList.props.$headPriceMyb, "hidden");

      bcl.c.hotelRoomList.props.$headPriceMyb.dataset.marketPrice = Math.ceil(bcl.c.hotelRoomList.props.priceMybDom);
      bcl.c.hotelRoomList.props.$headPriceMyb.dataset.relativeprice = bcl.c.hotelRoomList.props.priceMyb;
      bcl.c.hotelRoomList.props.$headPriceMyb.innerText = bcl.s.currency.formatPrice(bcl.c.hotelRoomList.props.$headPriceMyb.dataset.relativeprice);
      const currentTypeWrapperParent = bcl.c.hotelRoomList.props.$headPriceMyb.parentElement,
        currentTypeWrapper = currentTypeWrapperParent ? currentTypeWrapperParent.querySelector(bcl.c.hotelRoomList.props.priceSelectorMybCurrencyTypeWrapper) : null;

      currentTypeWrapper && bcl.u.removeClass(currentTypeWrapper, "hidden");
      if (bcl.s.currency.props.isRelativePriceRequest) {
        bcl.c.hotelRoomList.props.$headPriceMyb.dataset.relativeprice = bcl.c.hotelRoomList.props.priceMyb;
      } else {
        bcl.s.currency.convertPrices(bcl.c.hotelRoomList.props.$headPriceMyb);
      }

      bcl.c.hotelRoomList.setHotelHeadMybVisible();
    }

    bcl.u.removeClass(bcl.c.hotelRoomList.props.$head.children[0], "invisible");
    bcl.u.removeClass(bcl.c.hotelRoomList.props.$head.children[1], "invisible");
    bcl.u.removeSpinner(bcl.c.hotelRoomList.props.$head);

    bcl.c.hotelRoomList.updateUtagPriceData();
  },
  setHotelHeadMybVisible: function () {
    if (bcl.c.hotelRoomList.props.priceMyb && bcl.c.hotelRoomList.props.price && bcl.c.hotelRoomList.props.price > bcl.c.hotelRoomList.props.priceMyb) {
      const $tooltip = bcl.c.hotelRoomList.props.$headMyb.querySelector(".tooltip-full-JS");
      if ($tooltip?.classList.contains("hidden")) {
        bcl.u.removeClass($tooltip, "hidden");
      }

      if (bcl.c.hotelRoomList.props.$head.classList.contains("c-price-normal")) {
        bcl.u.removeClass(bcl.c.hotelRoomList.props.$head, "c-price-normal");
        bcl.u.addClass(bcl.c.hotelRoomList.props.$head, "c-price-normal-myb");
      }

      const $wrapper = bcl.c.hotelRoomList.props.$headMyb.querySelector(bcl.c.hotelRoomList.props.priceSelectorMybCurrencyTypeWrapper + ".c-price__type");
      if ($wrapper?.classList.contains("hidden")) {
        bcl.u.removeClass($wrapper, "hidden");
      }
    }
  },
  updateUtagPriceData: function () {
    const marketPrice = bcl.c.hotelRoomList.props.$headPrice.dataset.marketPrice,
      text = bcl.c.hotelRoomList.props.$headPrice.innerText.split(".").join("");

    if (marketPrice) {
      bcl.u.utag.add({
        booking_price: marketPrice,
        product_price: [marketPrice],
        booking_price_exchange: text,
      });
    } else {
      bcl.u.utag.add({
        booking_price: text,
        product_price: [text],
      });
    }
    if (bcl.c.hotelRoomList.props.mybPrices) {
      const myBPrice = parseInt(bcl.c.hotelRoomList.props.$headPriceMyb.innerText);
      const standardPrice = parseInt(bcl.c.hotelRoomList.props.$headPrice.innerText);
      if (!myBPrice || myBPrice >= standardPrice || !standardPrice) {
        document.querySelector(".switcher-close-btn-JS")?.click();
        bcl.c.hotelRoomList.props.$headMyb.classList.add("hidden");
      }
    }
  },
  //TODO: Move to utils function
  getListSorted: function (list, propToSort) {
    return list.sort(function (first, second) {
      if (first[propToSort] > second[propToSort]) {
        return -1;
      } else if (second[propToSort] > first[propToSort]) {
        return 1;
      } else {
        return 0;
      }
    });
  },
  getListSortedByRoom: function (propToSort) {
    let isDISPO = false;
    const firstRoom = document.querySelector(".hotel-room-list article[data-roomcode]").dataset.roomcode;
    const nodes = document.querySelectorAll(".hotel-room-list article[data-roomcode]");
    const nodeL = Array.prototype.slice.call(nodes, 0);
    const dispos = nodeL.filter((elem) => !elem.classList.contains("hidden"));
    const showMoreButton = document.querySelector(".pagination-JS");

    if (showMoreButton != null && showMoreButton != undefined) {
      showMoreButton.click();
    }

    dispos.forEach(function (elem) {
      const code = elem.dataset.roomcode;
      if (code == propToSort && code != undefined) {
        isDISPO = true;
        const cont = elem.closest(".c-hotel-room-my-barcelo-price");

        if (firstRoom != propToSort) {
          const parent = document.querySelector(".hotel-room-list");
          parent.append(cont);
          parent.insertBefore(cont, parent.children[0]);
        }

        if (!cont.classList.contains("mod--highlighted-card")) {
          cont.classList.add("mod--highlighted-card");
        }
      }
    });

    if (isDISPO) {
      utag.link({ ga4_event_name: "view_id_habitacion", event: "view", event_category: "adobe target", event_action: "PERSO | home-hotel-room-reorder", event_label: "si_perso" });
      adobe.target.trackEvent({
        mbox: "reordenacionHabitaciones",
        params: {
          reordenableHabitaciones: "OK",
        },
      });
    }
  },
  checkIfFilter: function (propToSort) {
    let isDISPO = false;
    const firstRoom = document.querySelector("[data-roomcode]").dataset.roomcode;
    if (firstRoom != propToSort) {
      const nodes = document.querySelectorAll("[data-roomcode]");
      const nodeL = Array.prototype.slice.call(nodes, 0);
      const dispos = nodeL.filter((elem) => !elem.classList.contains("hidden"));
      const showMoreButton = document.querySelector(".pagination-JS");
      if (showMoreButton != null && showMoreButton != undefined) {
        showMoreButton.click();
      }
      dispos.forEach(function (elem) {
        const code = elem.dataset.roomcode;
        if (code == propToSort && code != undefined) {
          isDISPO = true;
        }
      });
      if (isDISPO) {
        utag.link({ ga4_event_name: "view_id_habitacion", event: "view", event_category: "adobe target", event_action: "PERSO | home-hotel-room-reorder", event_label: "no_perso" });
        adobe.target.trackEvent({
          mbox: "reordenacionHabitaciones",
          params: {
            reordenableHabitaciones: "OK",
          },
        });
      }
    }
  },
  getListSortedByValue: function () {
    const showMoreButton = document.querySelector("." + bcl.c.hotelRoomList.props.paginationClassName);
    if (showMoreButton != null && showMoreButton != undefined) {
      showMoreButton.click();
    }
    const nodes = document.querySelectorAll(".c-hotel-room-list__footer-price");
    const nodeL = Array.prototype.slice.call(nodes, 0);
    const dispos = nodeL.filter((elem) => !elem.classList.contains("hidden"));
    const noDispos = nodeL.filter((elem) => elem.classList.contains("hidden"));
    dispos.sort(bcl.c.hotelRoomList.higherValueFilter);
    for (let i = 0; i < dispos.length; i++) {
      if (i == 0) {
        dispos[i].closest(".c-hotel-room-list").classList.add("mod--highlighted-card");
      }
      bcl.c.hotelRoomList.addToParent(dispos[i]);
    }
    noDispos.forEach((elem) => bcl.c.hotelRoomList.addToParent(elem));
    utag.link({ ga4_event_name: "view_id_habitacion", event: "view", event_category: "adobe target", event_action: "PERSO | home-hotel-room-reorder", event_label: "si_perso" });
    adobe.target.trackEvent({
      mbox: "reordenacionHabitaciones",
      params: {
        reordenableHabitaciones: "OK",
      },
    });
  },
  checkIfMVusersFilter: function () {
    const showMoreButton = document.querySelector("." + bcl.c.hotelRoomList.props.paginationClassName);
    if (showMoreButton != null && showMoreButton != undefined) {
      showMoreButton.click();
    }
    const nodes = document.querySelectorAll(".c-hotel-room-list__footer-price");
    const nodeL = Array.prototype.slice.call(nodes, 0);
    const dispos = nodeL.filter((elem) => !elem.classList.contains("hidden"));
    if (dispos.length > 0) {
      utag.link({ ga4_event_name: "view_id_habitacion", event: "view", event_category: "adobe target", event_action: "PERSO | home-hotel-room-reorder", event_label: "no_perso" });
      adobe.target.trackEvent({
        mbox: "reordenacionHabitaciones",
        params: {
          reordenableHabitaciones: "OK",
        },
      });
    }
  },
  higherValueFilter: function (a, b) {
    const firstPrice = parseInt(a.querySelector(".c-price__value-JS").getAttribute("data-market-price"));
    const secondPrice = parseInt(b.querySelector(".c-price__value-JS").getAttribute("data-market-price"));
    let comparison = 0;
    if (secondPrice > firstPrice) {
      comparison = 1;
    } else if (secondPrice < firstPrice) {
      comparison = -1;
    }
    if (a >= 1000 || b >= 1000) {
      comparison = -1;
    }
    return comparison;
  },
  addToParent: function (element) {
    const cont = element.closest(".c-hotel-room-my-barcelo-price");
    const parent = document.querySelector(".hotel-room-list");
    parent.append(cont);
  },

  updateOrder: function (data) {
    const orderToSort = bcl.c.fastbooking.commonProps.calendar.data.startDate && bcl.c.fastbooking.commonProps.calendar.data.endDate ? "value" : "order";

    bcl.c.hotelRoomList.getListSorted(bcl.c.hotelRoomList.props.dataPrices, orderToSort);

    const $container = document.querySelector("." + bcl.c.hotelRoomList.props.cssSelector + " > article");
    for (let i = 0; i < data.length; i++) {
      const $card = data[i].node;
      const firstChild = document.querySelector("." + bcl.c.hotelRoomList.props.cssSelector + " > article");

      if (!$card) {
        return;
      }

      bcl.u.removeClass($card, bcl.c.hotelRoomList.props.hidePagClassName);

      if (firstChild) {
        if (bcl.c.hotelRoomList.props.useOldAPI) {
          $container.parentNode.insertBefore($card, firstChild);
        } else {
          $container.parentNode.prepend(firstChild);
        }
      } else {
        $container.appendChild($card);
      }
    }

    //find min normal prices
    if (bcl.c.hotelRoomList.props.dataPrices.length > 0) {
      const minPrice = bcl.c.hotelRoomList.findMinPrice(bcl.c.hotelRoomList.props.dataPrices);
      bcl.c.hotelRoomList.props.price = minPrice !== null ? minPrice.value : bcl.c.hotelRoomList.props.price;
      bcl.c.hotelRoomList.props.priceMyb = minPrice !== null ? minPrice.valueMyb : bcl.c.hotelRoomList.props.priceMyb;
      if (bcl.c.hotelRoomList.props.priceMyb > bcl.c.hotelRoomList.props.price) {
        const priceNode = document.querySelector("." + bcl.c.hotelRoomList.props.headSelector);
        bcl.u.addClass(priceNode, "c-price-normal");
        bcl.u.removeClass(priceNode, "c-price-normal-myb");
        const mybPriceNode = document.querySelector(".c-head-hotel-info__booking-info-price-myb");
        bcl.u.addClass(mybPriceNode, "hidden");
      }
      if (document.body.dataset.currency === bcl.s.currency.props.currency.current) {
        bcl.c.hotelRoomList.props.priceMybDom = bcl.c.hotelRoomList.props.priceMyb;
      } else if (bcl.s.currency.props.relativeRatesMap) {
        const rate = bcl.s.currency.props.relativeRatesMap[document.body.dataset.currency].rate;
        bcl.c.hotelRoomList.props.priceMybDom = bcl.c.hotelRoomList.props.priceMyb * rate;
      }
    }

    bcl.c.hotelRoomList.loadDataInHeadHotel();
    bcl.c.hotelRoomList.hideRooms();
  },

  findMinPrice: function ($prices) {
    let minPrice = 10000000000;
    let result = null;
    bcl.u.forEach($prices, function ($currentNode) {
      if ($currentNode.value && $currentNode.value < minPrice) {
        minPrice = $currentNode.value;
        result = $currentNode;
      }
    });
    return result;
  },

  hideRooms: function () {
    const $rooms = document.querySelectorAll("." + bcl.c.hotelRoomList.props.cssSelector + " > article");
    for (let i = 0; i < $rooms.length; i++) {
      if (i >= bcl.c.hotelRoomList.props.numberPagination) {
        bcl.u.addClass($rooms[i], bcl.c.hotelRoomList.props.hidePagClassName);
      }
    }
  },

  getRoomsContainer: function () {
    return bcl.c.hotelRoomList.props.$container ? bcl.c.hotelRoomList.props.$container : document.getElementsByClassName(bcl.c.hotelRoomList.props.cssSelector)[0];
  },

  hideZeroPrice: function () {
    const price = bcl.c.hotelRoomList.props.$head.querySelector(bcl.c.hotelRoomList.props.priceSelector);
    if (price.textContent === "0") {
      bcl.u.addClass(bcl.c.hotelRoomList.props.$head, "hidden");
    } else {
      bcl.u.removeClass(bcl.c.hotelRoomList.props.$head, "hidden");
    }
  },

  updateCards: function () {
    const $container = bcl.c.hotelRoomList.getRoomsContainer();

    if (!$container) {
      if (!bcl.c.hotelRoomPage.props.firstLoad) {
        bcl.c.hotelRoomPage.updateUrl();
      }
      return;
    }

    const $rooms = $container.getElementsByClassName(bcl.c.hotelRoomList.props.itemCssSelector);

    if (!$rooms) {
      return;
    }

    bcl.c.hotelRoomList.props.hasFetched = false;
    bcl.c.hotelRoomList.cleanCards($rooms, $container);
    bcl.c.hotelRoomList.init();
  },

  initializeRoomsProps: function ($rooms, $container) {
    bcl.c.hotelRoomList.props.indexToPromise = 0;
    bcl.c.hotelRoomList.props.rooms = {};
    bcl.c.hotelRoomList.props.numHotels = $rooms.length;
    bcl.c.hotelRoomList.props.dataPrices = [];
    bcl.c.hotelRoomList.props.price = 10000000000;

    const $pagination = bcl.c.hotelRoomList.props.$pagination ? bcl.c.hotelRoomList.props.$pagination : $container.getElementsByClassName(bcl.c.hotelRoomList.props.paginationClassName)[0];

    if (bcl.u.containsClass($pagination, "invisible")) {
      bcl.u.removeClass($pagination, "invisible");
    }
  },

  updateCalendar: function () {
    bcl.c.fastbooking.calendar.applyRemoveAtAll([bcl.c.fastbooking.commonProps.calendar.dateCheckout, bcl.c.fastbooking.commonProps.calendar.dateCheckin]);
    bcl.c.fastbooking.calendar.addCookies();
  },

  updateRooms: function () {
    bcl.c.fastbooking.calendar.removeCookie(bcl.c.fastbooking.rooms.props.cookieName);
    bcl.c.fastbooking.calendar.addCookies();
  },

  cleanCards: function ($rooms, $container) {
    bcl.c.hotelRoomList.initializeRoomsProps($rooms, $container);

    bcl.c.fastbooking.calendar.updateOnlyCalendar ? bcl.c.hotelRoomList.updateCalendar() : bcl.c.hotelRoomList.updateRooms();

    Array.prototype.slice.call($rooms).forEach(function (value) {
      //call to clean
      const $footer = value.querySelector("." + bcl.c.hotelRoomList.props.elementSelector);
      bcl.c.hotelRoomList.showUnavailable($footer, bcl.c.hotelRoomList.props.removeClassName, bcl.c.hotelRoomList.props.addClassName);

      if (bcl.u.containsClass($footer, bcl.c.hotelRoomList.props.classAvailableFrom)) {
        bcl.u.removeClass($footer, bcl.c.hotelRoomList.props.classAvailableFrom);
      }

      const $button = document.querySelector("." + bcl.c.fastbooking.commonProps.fastbooking.selectors.hotelButton);

      if (bcl.u.containsClass($button, "mod--cta-disabled") && $button.disabled) {
        bcl.u.removeClass($button, "mod--cta-disabled");
        $button.disabled = false;
      }
    });
  },

  recommendRoom: function (roomRecommended) {
    const roomRecommendedContainer = document.querySelector(".hotel-room-recommended-container");
    roomRecommendedContainer?.style.height ? (roomRecommendedContainer.style.height = "inherit") : "";
    const roomRecommendedClone = roomRecommended.cloneNode(true);

    if (roomRecommendedContainer != null && roomRecommendedContainer.childElementCount <= 0) {
      const roomRecommendedTag = roomRecommendedClone.querySelector(".hotel-room-recommended-tag");
      const roomSizeSpan = roomRecommendedClone.querySelectorAll(".hotel-room-recommended-size");
      const roomBodyText = roomRecommendedClone.querySelectorAll(".c-hotel-room-list__body-text");
      const roomIconPersons = roomRecommendedClone.querySelectorAll(".c-hotel-room-list__body-persons div");
      const buttonBooking = roomRecommendedClone.querySelector(".booking-button-JS");

      const stripeMenu = document.querySelector(".c-stripemenu");

      if (!roomRecommendedClone.classList.contains("notAvailableRoom-JS")) {
        if (stripeMenu.classList.contains("mod--simple")) {
          roomRecommendedContainer.closest(".c-container__grid-wrapper").classList.add("--new-max-width");
        }
        bcl.u.removeClass(roomRecommendedTag, "hidden");
        bcl.u.removeClass(roomBodyText[0], "md:block");
        buttonBooking?.classList.replace("mod--cta-secondary", "mod--cta-primary");
        if (bcl.u.mobile.isMobile()) {
          bcl.u.removeClass(roomSizeSpan[0], "hidden");
          bcl.u.addClass(roomIconPersons[0], "hidden");
        } else {
          bcl.u.removeClass(roomSizeSpan[1], "hidden");
          bcl.u.addClass(roomIconPersons[1], "hidden");
        }
        roomRecommendedContainer.appendChild(roomRecommendedClone);
      }
    }
  },

  recommendRoomByCode: function (roomcode) {
    if (!roomcode) {
      return;
    }

    const roomRecommendedContainer = document.querySelector(".hotel-room-recommended-container");
    if (roomRecommendedContainer && roomRecommendedContainer.childElementCount > 0) {
      roomRecommendedContainer.innerHTML = "";
    }

    const roomRecommended = bcl.c.hotelRoomList.props.$container.querySelector(`.${bcl.c.hotelRoomList.props.itemCssSelector}[data-roomcode="${roomcode}"]`);
    if (roomRecommended) {
      bcl.c.hotelRoomList.recommendRoom(roomRecommended);
    }
  },

  checkOldAPI: function () {
    bcl.c.hotelRoomList.props.useOldAPI = document.body.classList.contains("mod--enable-sabre-API") || utag_data?.hotel_country == "cuba";
  },
};

bcl.preInit.push(bcl.c.hotelRoomList.preInit);
