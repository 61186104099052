bcl.c.hotelRoomPage = {
  props: {
    $headRoom: null,
    firstLoad: true,
    orientativeMessage: ".c-header-detail__booking-claim",
    unavailableMessage: ".unavailable-content",
    price: ".c-header-detail__booking-price",
    headWrap: ".c-header-detail__booking-wrap",
  },

  initHeadRoomPage: function () {
    const $container = document.querySelector(".c-header-detail__booking");

    if (!$container) {
      return;
    }
    bcl.c.hotelRoomList.props.$headRoom = $container;
    bcl.u.addClass(bcl.c.hotelRoomList.props.$headRoom.children[1], "hidden");
  },

  loadDataInHeadHotelRoom: function () {
    if (!bcl.c.hotelRoomList.props.$headRoom) {
      return;
    }

    bcl.c.hotelRoomList.props.$headPrice = bcl.c.hotelRoomList.props.$headRoom.querySelector(bcl.c.hotelRoomList.props.priceSelector);

    if (bcl.c.hotelRoomList.props.price != 10000000000 && bcl.c.hotelRoomList.props.price != 0 && bcl.c.hotelRoomList.props.$headPrice) {
      bcl.c.hotelRoomList.props.$headPrice.dataset.marketPrice = bcl.c.hotelRoomList.props.price;
      bcl.c.hotelRoomList.props.$headPrice.innerText = bcl.s.currency.formatPrice(bcl.c.hotelRoomList.props.price);
      bcl.s.currency.convertPrices(bcl.c.hotelRoomList.props.$headRoom);
      bcl.c.hotelRoomPage.showOrHideLayout(bcl.c.hotelRoomList.props.removeClassName);
    }
    bcl.u.removeSpinner(bcl.c.hotelRoomList.props.$headRoom);
  },

  updateUrl: function () {
    //TODO: REFACTOR
    bcl.c.hotelRoomPage.initHeadRoomPage();

    bcl.c.hotelRoomPage.showOrHideLayout(bcl.c.hotelRoomList.props.addClassName);

    bcl.c.hotelRoomList.initSelectors();

    const $el = document.querySelector("[data-async-load]");
    const hotelId = bcl.c.hotelRoomList.props.$headRoom.dataset.hotelId;
    let oldUrl = $el.getAttribute("data-async-load");

    if (bcl.c.hotelRoomList.props.selectors) {
      const priceParams = new URLSearchParams({
        start_date: bcl.c.hotelRoomList.props.selectors.startDate,
        end_date: bcl.c.hotelRoomList.props.selectors.endDate,
        market: document.querySelector("body").getAttribute("data-ratefilter"),
        adults: bcl.c.hotelRoomList.props.selectors.numberAdults,
        children: bcl.c.hotelRoomList.props.selectors.numberChildren,
      });

      const url = document.body.dataset.bconnectUrl.replace("[HOTEL_ID]", hotelId) + "/by-rooms?" + priceParams.toString();
      // const url = `https://reservation-api.barcelo.com/hotel-availability-adapter/v1/hotels/${hotelId}/availability/by-rooms?${priceParams.toString()}`;
      oldUrl = url;

      const config = { headers: { "X-CBE-Customer-Currency": bcl.s.currency.props.currency.current } };

      fetch(url, config)
        .then((response) => response.json())
        .then((res) => {
          if (res.code) {
            bcl.c.hotelRoomPage.showOrHideLayout(bcl.c.hotelRoomList.props.addClassName);
            return;
          }
          bcl.c.hotelRoomPage.setPriceHeaderDetail(res.rooms);
        })
        .catch((err) => console.error(err));

      $el.setAttribute("data-async-load", url);
    }
    bcl.u.addSpinner($el);

    // bcl.u.loadAsyncData();

    $el.setAttribute("data-async-load", oldUrl);
  },
  replaceCurrencyUrl: function (url, oldUrlSelectors) {
    if (bcl.s.currency?.props.marketRelativeRatesMap && bcl.s.currency?.props.currency.current) {
      const oldUrlCurrency = oldUrlSelectors[oldUrlSelectors.length - 2];
      if (oldUrlCurrency && Object.keys(bcl.s.currency.props.marketRelativeRatesMap).includes(oldUrlCurrency)) {
        const currentCurrency = bcl.s.currency.props.currency.current;
        if (oldUrlCurrency != currentCurrency) {
          return url.replace("." + oldUrlCurrency, "." + currentCurrency);
        }
      }
    }
    return url;
  },
  showOrHideLayout: function (method) {
    const $el = bcl.c.hotelRoomList.props.$headRoom;
    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "flex");
    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "justify-center");

    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.orientativeMessage), "hidden");
    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.price), "hidden");

    bcl.u.addClass($el.querySelector(bcl.c.hotelRoomPage.props.unavailableMessage), "hidden");

    bcl.u[method]($el.querySelector(".booking-button-JS:not(.force-show-JS)"), "hidden");
  },

  showUnavailable: function ($el, firstMethod, secondaryMethod) {
    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "flex");
    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "justify-center");

    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.orientativeMessage), "hidden");

    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.price), "hidden");
    bcl.u[secondaryMethod]($el.querySelector(bcl.c.hotelRoomPage.props.unavailableMessage), "hidden");
    bcl.u[firstMethod]($el.querySelector(".booking-button-JS"), "hidden");
  },

  setPriceHeaderDetail: function (rooms) {
    if (!bcl.c.hotelRoomList.props.$headRoom) {
      return;
    }

    const roomCode = bcl.c.hotelRoomList.props.$headRoom.dataset.roomcode;
    const roomData = rooms.find((room) => room.id == roomCode);
    const priceDiv = document.querySelector(bcl.c.hotelRoomPage.props.price).querySelector(".c-price-JS .c-price__value-JS");

    if (roomData.restriction.status == bcl.c.hotelRoomList.props.restrictions.bconnect.open) {
      if (roomData && roomData.rates?.average?.total) {
        const totalWithTaxes = roomData.rates.average.total;
        if (totalWithTaxes.converted) {
          priceDiv.innerText = bcl.u.calculateDecimalsByExponent(totalWithTaxes.converted.value, totalWithTaxes.converted.exponent);
        } else {
          priceDiv.innerText = bcl.u.calculateDecimalsByExponent(totalWithTaxes.value, totalWithTaxes.exponent);
        }
      }
      bcl.c.hotelRoomPage.showOrHideLayout(bcl.c.hotelRoomList.props.removeClassName);
    } else {
      bcl.c.hotelRoomPage.showUnavailable(document.querySelector(".c-header-detail__booking"), bcl.c.hotelRoomList.props.addClassName, bcl.c.hotelRoomList.props.removeClassName);
    }
  },
};
